var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-navigation-drawer',{attrs:{"right":"","temporary":"","fixed":"","width":"600"},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-row',{staticClass:"mr-4 mb-4"},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","dark":"","loading":_vm.isSaving},on:{"click":_vm.onConfirmSelected}},[_vm._v("Guardar")])],1)]},proxy:true}]),model:{value:(_vm.drawerActive),callback:function ($$v) {_vm.drawerActive=$$v},expression:"drawerActive"}},[(_vm.customer)?_c('v-container',{staticClass:"pa-10"},[_c('v-row',[_c('v-col',[_c('v-row',{staticClass:"align-center pb-6"},[_c('v-btn',{staticClass:"black--text",attrs:{"icon":""},on:{"click":_vm.onCancelSelected}},[_c('v-icon',[_vm._v("mdi-chevron-left")])],1),_c('span',{staticClass:"headline",staticStyle:{"white-space":"nowrap"}},[_vm._v("Modificar cliente")])],1),_c('validation-observer',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
return [_c('v-form',[(!_vm.customer.isWholesaler)?_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"firstName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"id":"customerFirstName","name":"customerFirstName","autocomplete":"customerFirstName","x-autocompletetype":"customerFirstName","label":_vm.$t('fields.firstName'),"error-messages":errors},model:{value:(_vm.customerFirstName),callback:function ($$v) {_vm.customerFirstName=$$v},expression:"customerFirstName"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"lastName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"id":"customerLastName","name":"customerLastName","autocomplete":"customerLastName","x-autocompletetype":"customerLastName","label":_vm.$t('fields.lastName'),"error-messages":errors},model:{value:(_vm.customerLastName),callback:function ($$v) {_vm.customerLastName=$$v},expression:"customerLastName"}})]}}],null,true)})],1)],1):_c('v-row',[_c('validation-provider',{attrs:{"name":"companyName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"id":"customerCompanyName","name":"customerCompanyName","autocomplete":"customerCompanyName","x-autocompletetype":"customerCompanyName","label":_vm.$t('fields.companyName'),"error-messages":errors},model:{value:(_vm.customerCompanyName),callback:function ($$v) {_vm.customerCompanyName=$$v},expression:"customerCompanyName"}})]}}],null,true)})],1),_c('validation-provider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"id":"customerEmail","autocomplete":"customerEmail","x-autocompletetype":"customerEmail","label":_vm.$t('fields.email'),"error-messages":errors},model:{value:(_vm.customerEmail),callback:function ($$v) {_vm.customerEmail=$$v},expression:"customerEmail"}})]}}],null,true)})],1)]}}],null,false,2825170453)})],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }