<template>
    <v-navigation-drawer
        v-model="active"
        right
        temporary
        fixed
        width="600"
    >
        <v-container class="pa-10">
            <v-row>
                <v-col>
                    <v-row class="align-center pb-6">
                        <v-btn icon class="black--text" @click="onBackSelected">
                            <v-icon>mdi-chevron-left</v-icon>
                        </v-btn>

                        <span class="headline" style="white-space: nowrap">Eliminar dirección de entrega</span>
                    </v-row>

                    <v-list dense v-if="address" class="t-info-list">
                        <v-list-item>
                            <v-list-item-content>Dirección</v-list-item-content>
                            <v-list-item-content>{{ address.address }}</v-list-item-content>
                        </v-list-item>

                        <v-list-item v-if="address.unit">
                            <v-list-item-content>Unidad</v-list-item-content>
                            <v-list-item-content>{{ address.unit }}</v-list-item-content>
                        </v-list-item>

                        <v-list-item v-if="address.area">
                            <v-list-item-content>Localidad</v-list-item-content>
                            <v-list-item-content>{{ address.area }}</v-list-item-content>
                        </v-list-item>

                        <v-list-item v-if="address.place">
                            <v-list-item-content>Lugar</v-list-item-content>
                            <v-list-item-content>{{ address.place }}</v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-col>
            </v-row>
        </v-container>

        <template v-slot:append>
            <v-row class="ma-4">
                <v-spacer></v-spacer>

                <v-btn color="red" block dark @click="onConfirmSelected" :loading="isSaving">Eliminar</v-btn>
            </v-row>
        </template>
    </v-navigation-drawer>
</template>

<script>
    export default {
        components: {
        },

        props: {
            customer: {
                type: Object,
                default: null
            },

            address: {
                type: Object,
                default: null
            },

            active: {
                type: Boolean,
                default: false
            }
        },

        data() {
            return {
                isSaving: false
            };
        },

        watch: {
            active(val) {
                this.$emit('update:active', val);
            }
        },

        methods: {
            async onConfirmSelected() {
                this.isSaving = true;

                await this.$store.dispatch('customer/removeShippingAddress', {
                    customer: this.customer,
                    address: this.address
                });

                this.isSaving = false;
                this.$emit('update:active', false);
            },

            onBackSelected(value) {
                this.$emit('update:active', false);
            }
        }
    };
</script>
