<template>
    <v-data-table
        v-if="billingAddresses"
        :items="billingAddresses"
        :headers="headers"
        no-data-text="No hay direcciones para mostrar"
        hide-default-footer
        :items-per-page="-1"
    >
        <template v-slot:item.actions="{ item }">
            <div align="right">
                <slot name="actions" v-bind:address="item"></slot>
            </div>
        </template>
    </v-data-table>
</template>

<script>
    export default {
        props: {
            billingAddresses: {
                type: Array,
                default() {
                    return [];
                }
            }
        },

        data() {
            return {
                headers: [
                    { text: 'Razón Social', value: 'name', sortable: false },
                    { text: 'CUIT/DNI', value: 'cuit', sortable: false },
                    { text: 'Tipo', value: 'ivaConditionName', sortable: false },
                    { text: 'Dirección Fiscal', value: 'address', sortable: false },
                    { text: '', value: 'actions', sortable: false }
                ]
            };
        },

        mounted() {

        },

        methods: {}
    };
</script>

<style lang="scss" scoped>

</style>
