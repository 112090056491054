<template>
    <template-layout>
        <template slot="content">
            <customer-shipping-address-create-drawer
                :active.sync="showShippingAddressCreateDrawer"
                :customer="customer"
            />

            <customer-shipping-address-delete-drawer
                :active.sync="showShippingAddressDeleteDrawer"
                :customer="customer"
                :address="selectedShippingAddress"
            />

            <customer-billing-address-create-drawer
                :active.sync="showBillingAddressCreateDrawer"
                :customer="customer"
            />

            <customer-billing-address-delete-drawer
                :active.sync="showBillingAddressDeleteDrawer"
                :customer="customer"
                :address="selectedBillingAddress"
            />

            <customer-edit-drawer
                :active.sync="showCustomerEditDrawer"
                :customer="customer"
            />

            <v-container v-if="customer">
                <v-toolbar flat dense color="transparent" height="60" class="mb-2">
                    <v-toolbar-title>
                        <span class="headline" style="white-space: nowrap">{{ customer.fullName | capitalize }}</span>

                        <span class="text--lighten-1 grey--text ml-2"># {{ customer.id }}</span>
                    </v-toolbar-title>

                    <v-spacer/>

                    <v-toolbar-title>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-dialog
                                    v-model="showDeleteConfirmation"
                                    width="500"
                                    >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn icon color="red" v-bind="attrs" v-on="on" :disabled="!canDelete"><v-icon>mdi-delete</v-icon></v-btn>
                                    </template>

                                    <v-card>
                                        <v-card-title class="text-h5 red">
                                            Confirmar
                                        </v-card-title>

                                        <v-card-text class="text--black">
                                            Esta seguro que desea eliminar el cliente?
                                        </v-card-text>

                                        <v-card-actions>
                                            <v-spacer></v-spacer>
                                            <v-btn color="red" dark @click="onDeleteSelected">
                                                Eliminar cliente
                                            </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-dialog>
                            </template>
                            <span>Eliminar cliente</span>
                        </v-tooltip>
                    </v-toolbar-title>
                </v-toolbar>

                <v-row>
                    <v-col cols="4">
                        <v-card>
                            <v-card-text>
                                <h5 class="font-weight-regular grey--text">Pedidos</h5>
                                <h1 class="display-1 black--text">{{ ordersTotal }}</h1>
                            </v-card-text>
                        </v-card>
                    </v-col>

                    <v-col cols="4">
                        <v-card>
                            <v-card-text>
                                <h5 class="font-weight-regular grey--text">Deuda</h5>
                                <h1 v-if="!isNaN(customer.debt)" class="display-1 black--text">{{ customer.debt | toCurrency }}</h1>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>

                <v-row>
                    <!-- Left Column -->
                    <v-col cols="12" lg="4" class="mb-7">
                        <v-card class="mb-7">
                            <v-card-title>
                                <span>Información</span>

                                <v-spacer/>

                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-btn icon small @click.stop="showCustomerEditDrawer = true" v-on="on">
                                            <v-icon>mdi-calendar-edit</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Configuración</span>
                                </v-tooltip>
                            </v-card-title>

                            <v-card-text>
                                <customer-view-info :customer="customer"></customer-view-info>
                            </v-card-text>
                        </v-card>

                        <v-card>
                            <v-card-title>
                                <span class="title">Usuario</span>
                            </v-card-title>

                            <v-card-text v-if="customer.user">
                                <v-list dense class="t-info-list body-1">
                                    <v-list-item>
                                        <v-list-item-content>Usuario</v-list-item-content>
                                        <v-list-item-content>{{ customer.user.username }}</v-list-item-content>
                                    </v-list-item>

                                    <v-list-item>
                                        <v-list-item-content>Email</v-list-item-content>
                                        <v-list-item-content>{{ customer.user.email }}</v-list-item-content>
                                    </v-list-item>

                                    <v-list-item>
                                        <v-list-item-content>Verificado</v-list-item-content>
                                        <v-list-item-content>
                                            <v-icon class="justify-end" small :color="customer.user.isVerified ? 'green' : 'red'">mdi-circle</v-icon>
                                        </v-list-item-content>
                                    </v-list-item>

                                    <v-list-item>
                                        <v-list-item-content>Estado</v-list-item-content>
                                        <v-list-item-content>
                                            <v-icon class="justify-end" small :color="customer.user.isActive ? 'green' : 'red'">mdi-circle</v-icon>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                            </v-card-text>

                            <v-card-text v-else>
                                Sin usuario
                            </v-card-text>
                        </v-card>
                    </v-col>

                    <!-- Right Column -->
                    <v-col cols="12" lg="8">
                        <v-card>
                            <v-card-title>
                                <h1 class="headline black--text">Pedidos</h1>
                            </v-card-title>

                            <v-card-text>
                                <customer-view-orders :customer="customer" :orders="orders"></customer-view-orders>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>

                <v-card class="mb-7">
                    <v-card-title>
                        <v-col>
                            <span class="title">Direcciones de Entrega</span>
                        </v-col>

                        <v-col class="text-end">
                            <v-btn icon @click="showShippingAddressCreateDrawer = !showShippingAddressCreateDrawer"><v-icon>mdi-plus</v-icon></v-btn>
                        </v-col>
                    </v-card-title>

                    <v-card-text>
                        <customer-view-shipping-addresses :shipping-addresses="customer.shippingAddresses">
                            <template v-slot:actions="{ address }">
                                <v-btn icon @click="onRemoveShippingAddressSelected(address)"><v-icon>mdi-close</v-icon></v-btn>
                            </template>
                        </customer-view-shipping-addresses>
                    </v-card-text>
                </v-card>

                <v-card class="mb-7">
                    <v-card-title>
                        <v-col>
                            <span class="title">Datos Fiscales</span>
                        </v-col>

                        <v-col class="text-end">
                            <v-btn icon @click="showBillingAddressCreateDrawer = !showBillingAddressCreateDrawer"><v-icon>mdi-plus</v-icon></v-btn>
                        </v-col>
                    </v-card-title>

                    <v-card-text>
                        <customer-view-billing-addresses :billing-addresses="customer.billingAddresses">
                            <template v-slot:actions="{ address }">
                                <v-btn icon @click="onRemoveBillingAddressSelected(address)"><v-icon>mdi-close</v-icon></v-btn>
                            </template>
                        </customer-view-billing-addresses>
                    </v-card-text>
                </v-card>
            </v-container>
        </template>
    </template-layout>
</template>

<script>
    import TemplateLayout from '@/layout/TemplateLayout.vue';
    import CustomerViewInfo from './CustomerViewInfo';
    import CustomerViewOrders from './CustomerViewOrders';
    import CustomerViewShippingAddresses from './CustomerViewShippingAddresses';
    import CustomerViewBillingAddresses from './CustomerViewBillingAddresses';
    import CustomerShippingAddressCreateDrawer from '@/components/Customer/CustomerShippingAddressCreateDrawer';
    import CustomerBillingAddressCreateDrawer from '@/components/Customer/CustomerBillingAddressCreateDrawer';
    import CustomerShippingAddressDeleteDrawer from '@/components/Customer/CustomerShippingAddressDeleteDrawer';
    import CustomerBillingAddressDeleteDrawer from '@/components/Customer/CustomerBillingAddressDeleteDrawer';
    import CustomerEditDrawer from './CustomerEditDrawer.vue';

    export default {
        components: {
            CustomerViewBillingAddresses,
            CustomerViewShippingAddresses,
            CustomerShippingAddressCreateDrawer,
            CustomerBillingAddressCreateDrawer,
            CustomerShippingAddressDeleteDrawer,
            CustomerBillingAddressDeleteDrawer,
            CustomerViewOrders,
            CustomerViewInfo,
            TemplateLayout,
            CustomerEditDrawer
        },

        data() {
            return {
                showCustomerEditDrawer: false,
                showShippingAddressCreateDrawer: false,
                showBillingAddressCreateDrawer: false,
                showShippingAddressDeleteDrawer: false,
                showBillingAddressDeleteDrawer: false,
                showDeleteConfirmation: false,

                selectedShippingAddress: null,
                selectedBillingAddress: null
            };
        },

        watch: {
            '$route.params.id': function(id) {
                this.$store.dispatch('customer/fetchOne', parseInt(this.$route.params.id));
            },

            customer(val) {
                if(val) {
                    this.$store.dispatch('customer/fetchShippingAddresses', { customer: val });
                    this.$store.dispatch('customer/fetchBillingAddresses', { customer: val });
                }
            },

            showShippingAddressDeleteDrawer(val) {
                if(!val) {
                    this.selectedShippingAddress = null;
                }
            },

            showBillingAddressDeleteDrawer(val) {
                if(!val) {
                    this.selectedBillingAddress = null;
                }
            }
        },

        computed: {
            isLoading() {
                return this.$store.getters['customer/isLoading']();
            },

            customer() {
                return this.$store.getters['customer/getById'](parseInt(this.$route.params.id));
            },

            canDelete() {
                return !this.isLoading && this.orders.length === 0;
            },

            orders() {
                if(!this.customer || !this.customer.orders) {
                    return [];
                }

                return this.customer.orders.map(id => this.$store.getters['order/getById'](id));
            },

            ordersTotal() {
                return this.customer.ordersTotal;
            }
        },

        async mounted() {
            await this.$store.dispatch('customer/fetchOne', parseInt(this.$route.params.id));
            await this.$store.dispatch('customer/fetchDebt', parseInt(this.$route.params.id));
        },

        methods: {
            onBackSelected() {
                if(window.history.length > 2) {
                    this.$router.go(-1);
                }
                else {
                    this.$router.push({ name: 'customerList' });
                }
            },

            onRemoveShippingAddressSelected(address) {
                this.selectedShippingAddress = address;
                this.showShippingAddressDeleteDrawer = true;
            },

            onRemoveBillingAddressSelected(address) {
                this.selectedBillingAddress = address;
                this.showBillingAddressDeleteDrawer = true;
            },

            async onDeleteSelected() {
                try {
                    await this.$store.dispatch('customer/delete', { customer: this.customer });

                    this.onBackSelected();
                }
                finally {
                    this.showDeleteConfirmation = false;
                }
            }
        }
    };
</script>

<style lang="scss" scoped>
    .v-chip.small {
        height: 20px;
    }

    .v-card.info-card {
        .v-card__title {
            font-size: 1.1rem;
            padding-bottom: 0;
            color: #a3a4a6;
            border: none;
        }

        .v-card__text {
            padding-top: 0;
            font-size: 2.0rem;
        }
    }
</style>
