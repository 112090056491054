var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.orders)?_c('v-data-table',{attrs:{"items":_vm.orders,"options":_vm.options,"server-items-length":_vm.ordersTotal,"headers":_vm.headers,"footer-props":_vm.footer,"no-data-text":"No hay pedidos para mostrar","hide-default-header":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.deliveryDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.deliveryDate,'D/M/Y'))+" ")]}},{key:"item.state",fn:function(ref){
var item = ref.item;
return [_c('tui-order-state-chip',{attrs:{"shrink":true,"state":item.state}})]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toCurrency")(item.total))+" ")]}},{key:"item.payment",fn:function(ref){
var item = ref.item;
return [_c('tui-order-payment-status-chip',{attrs:{"order":item}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{attrs:{"align":"right"}},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.onViewSelected(item)}}},[_c('v-icon',[_vm._v("mdi-magnify")])],1)],1)]}}],null,false,2884025902)}):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }