<template>
    <v-data-table
        v-if="shippingAddresses"
        :items="shippingAddresses"
        :headers="headers"
        no-data-text="No hay direcciones para mostrar"
        hide-default-footer
        :items-per-page="-1"
    >
        <template v-slot:item.area="{ item }">
            {{ item.area }} {{ item.place }}
        </template>

        <template v-slot:item.actions="{ item }">
            <div align="right">
                <slot name="actions" v-bind:address="item"></slot>
            </div>
        </template>
    </v-data-table>
</template>

<script>
    export default {
        props: {
            shippingAddresses: {
                type: Array,
                default() {
                    return [];
                }
            }
        },

        data() {
            return {
                pagination: {
                    sortBy: 'id',
                    descending: true
                },
                rowsPerPageItems: [10],

                headers: [
                    { text: 'Dirección', value: 'address', sortable: false },
                    { text: 'Unidad', value: 'unit', sortable: false },
                    { text: 'Localidad', value: 'area', sortable: false },
                    { text: 'Teléfono', value: 'phone', sortable: false },
                    { text: 'Celular', value: 'cellphone', sortable: false },
                    { text: '', value: 'actions', sortable: false }
                ]
            };
        },

        mounted() {

        },

        methods: {}
    };
</script>

<style lang="scss" scoped>

</style>
