<template>
    <v-list v-if="customer" dense class="t-info-list body-1">
        <v-list-item>
            <v-list-item-content>Nombre</v-list-item-content>
            <v-list-item-content>{{ customer.fullName }}</v-list-item-content>
        </v-list-item>

        <v-list-item>
            <v-list-item-content>Email</v-list-item-content>
            <v-list-item-content class="d-flex justify-end">{{ customer.email }}</v-list-item-content>
        </v-list-item>

        <v-list-item>
            <v-list-item-content>Fecha de Alta</v-list-item-content>
            <v-list-item-content class="d-flex justify-end">{{ customer.createdAt | moment('D/M/Y') }}</v-list-item-content>
        </v-list-item>

        <v-list-item>
            <v-list-item-content>Tipo de cliente</v-list-item-content>
            <v-list-item-content class="d-flex justify-end" v-if="customer.isWholesaler">Mayorista</v-list-item-content>
            <v-list-item-content class="d-flex justify-end" v-else>Minorista</v-list-item-content>
        </v-list-item>
    </v-list>
</template>

<script>
    export default {
        components: {},

        props: {
            customer: {
                type: Object,
                default() {
                    return {};
                }
            }
        },

        data() {
            return {};
        },

        mounted() {

        },

        methods: {}
    };
</script>

<style lang="scss" scoped>

</style>
