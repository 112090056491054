<template>
    <v-data-table
        v-if="orders"
        :items="orders"
        :options.sync="options"
        :server-items-length="ordersTotal"
        :headers="headers"
        :footer-props="footer"

        no-data-text="No hay pedidos para mostrar"
        hide-default-header
    >
        <template v-slot:item.deliveryDate="{item}">
            {{ item.deliveryDate | moment('D/M/Y') }}
        </template>

        <template v-slot:item.state="{item}">
            <tui-order-state-chip :shrink="true" :state="item.state"></tui-order-state-chip>
        </template>

        <template v-slot:item.total="{item}">
            {{ item.total | toCurrency }}
        </template>

        <template v-slot:item.payment="{item}">
            <tui-order-payment-status-chip :order="item"></tui-order-payment-status-chip>
        </template>

        <template v-slot:item.actions="{item}">
            <div align="right">
                <v-btn icon @click.stop="onViewSelected(item)">
                    <v-icon>mdi-magnify</v-icon>
                </v-btn>
            </div>
        </template>
    </v-data-table>
</template>

<script>
    import TuiOrderStateChip from '@/ui/TUIOrderStateChip';
    import TuiOrderPaymentStatusChip from '@/ui/TUIOrderPaymentStatusChip';

    export default {
        components: {
            TuiOrderPaymentStatusChip,
            TuiOrderStateChip
        },

        props: {
            customer: {
                type: Object,
                default() {
                    return {};
                }
            },

            orders: {
                type: Array
            }
        },

        data() {
            return {
                options: {
                    itemsPerPage: 5
                },

                headers: [
                    { text: 'Pedido #', value: 'id', sortable: false },
                    { text: 'Entrega', value: 'deliveryDate', sortable: false },
                    { text: 'Estado', value: 'state', sortable: false },
                    { text: 'Importe', value: 'total', sortable: false },
                    { text: 'Pago', value: 'payment', sortable: false },
                    { text: '', value: 'actions' }
                ],

                footer: {
                    showCurrentPage: true,
                    showFirstLastPage: true,
                    disableItemsPerPage: true,
                    itemsPerPageText: ''
                }
            };
        },

        computed: {
            ordersTotal() {
                return this.customer.ordersTotal;
            }
        },

        watch: {
            customer(val) {
                this.fetchOrders();
            },

            options: {
                handler() {
                    this.fetchOrders();
                },

                deep: true
            }
        },

        mounted() {
            this.fetchOrders();
        },

        methods: {
            async fetchOrders() {
                await this.$store.dispatch('customer/fetchOrders', {
                    customer: this.customer,
                    offset: (this.options.page - 1) * this.options.itemsPerPage,
                    limit: this.options.itemsPerPage
                });
            },

            onViewSelected(order) {
                this.$router.push({ name: 'orderView', params: { id: order.id } });
            }
        }
    };
</script>

<style lang="scss" scoped>

</style>
