<template>
    <v-navigation-drawer
        v-model="drawerActive"
        right
        temporary
        fixed
        width="600"
    >
        <v-container class="pa-10" v-if="customer">
            <v-row>
                <v-col>
                    <v-row class="align-center pb-6">
                        <v-btn icon class="black--text" @click="onCancelSelected">
                            <v-icon>mdi-chevron-left</v-icon>
                        </v-btn>
                        <span class="headline" style="white-space: nowrap">Modificar cliente</span>
                    </v-row>

                    <validation-observer ref="form" v-slot="{ valid }">
                        <v-form>
                            <v-row v-if="!customer.isWholesaler">
                                <v-col cols="6">
                                    <validation-provider name="firstName" v-slot="{ errors }" rules="required">
                                        <v-text-field
                                            v-model="customerFirstName"
                                            id="customerFirstName"
                                            name="customerFirstName"
                                            autocomplete="customerFirstName"
                                            x-autocompletetype="customerFirstName"
                                            :label="$t('fields.firstName')"
                                            :error-messages="errors"
                                        />
                                    </validation-provider>
                                </v-col>

                                <v-col cols="6">
                                    <validation-provider name="lastName" v-slot="{ errors }" rules="required">
                                        <v-text-field
                                            v-model="customerLastName"
                                            id="customerLastName"
                                            name="customerLastName"
                                            autocomplete="customerLastName"
                                            x-autocompletetype="customerLastName"
                                            :label="$t('fields.lastName')"
                                            :error-messages="errors"
                                        />
                                    </validation-provider>
                                </v-col>
                            </v-row>

                            <v-row v-else>
                                <validation-provider name="companyName" v-slot="{ errors }" rules="required">
                                    <v-text-field
                                        v-model="customerCompanyName"
                                        id="customerCompanyName"
                                        name="customerCompanyName"
                                        autocomplete="customerCompanyName"
                                        x-autocompletetype="customerCompanyName"
                                        :label="$t('fields.companyName')"
                                        :error-messages="errors"
                                    />
                                </validation-provider>
                            </v-row>

                            <validation-provider name="email" v-slot="{ errors }" rules="required|email">
                                <v-text-field
                                    v-model="customerEmail"
                                    id="customerEmail"
                                    autocomplete="customerEmail"
                                    x-autocompletetype="customerEmail"
                                    :label="$t('fields.email')"
                                    :error-messages="errors"
                                />
                            </validation-provider>
                        </v-form>
                    </validation-observer>
                </v-col>
            </v-row>
        </v-container>

        <template v-slot:append>
            <v-row class="mr-4 mb-4">
                <v-spacer></v-spacer>

                <v-btn color="primary" dark @click="onConfirmSelected" :loading="isSaving">Guardar</v-btn>
            </v-row>
        </template>
    </v-navigation-drawer>
</template>

<script>
    import { ValidationProvider, ValidationObserver } from 'vee-validate';

    export default {
        components: {
            ValidationProvider,
            ValidationObserver
        },

        props: {
            customer: {
                type: Object,
                default: null
            },

            active: {
                type: Boolean,
                default: false
            }
        },

        data() {
            return {
                isSaving: false,
                customerFirstName: '',
                customerLastName: '',
                customerCompanyName: '',
                customerEmail: ''
            };
        },

        computed: {
            drawerActive: {
                get() {
                    return this.active;
                },

                set(val) {
                    this.$emit('update:active', val);
                }
            }
        },

        watch: {
            customer(val) {
                if(val) {
                    this.customerFirstName = val.firstName;
                    this.customerLastName = val.lastName;
                    this.customerCompanyName = val.companyName;
                    this.customerEmail = val.email;
                }
                else {
                    this.customerFirstName = '';
                    this.customerLastName = '';
                    this.customerCompanyName = '';
                    this.customerEmail = '';
                }
            }
        },

        methods: {
            async onConfirmSelected() {
                let payload = {
                    firstName: this.customerFirstName,
                    lastName: this.customerLastName,
                    companyName: this.customerCompanyName,
                    email: this.customerEmail
                };

                this.isSaving = true;

                try {
                    await this.$store.dispatch('customer/patch', {
                        customer: this.customer,
                        data: payload
                    });

                    this.$store.dispatch('app/setStatusMessage', 'Cambios guardados');

                    this.drawerActive = false;
                }
                catch (err) {
                    if(err.code === 'error.request.invalid') {
                        let formErrors = {};

                        for(let errField in err.errors) {
                            formErrors[errField] = this.$t(err.errors[errField]);
                        }

                        this.$refs.form.setErrors(formErrors);
                    }
                }
                finally {
                    this.isSaving = false;
                }
            },

            onCancelSelected(value) {
                this.$emit('update:active', false);
            }
        }
    };
</script>
