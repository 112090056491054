<template>
    <v-chip
        label
        x-small-chip
        :color="color"
        text-color="white"
    >
        {{ name }}
    </v-chip>
</template>

<script>
    export default {
        name: 'tui-order-payment-status-chip',

        props: {
            order: {
                type: Object,
                default() {
                    return null;
                }
            }
        },

        computed: {
            name() {
                if(this.order.paidUp === 0 && this.order.total > 0) {
                    return 'Impago';
                }
                else if(this.order.paidUp < this.order.total) {
                    return 'Parcial';
                }
                else if(this.order.paidUp >= this.order.total) {
                    return 'Pago';
                }
                else {
                    return 'N/D';
                }
            },

            color() {
                if(this.order.paidUp === 0 && this.order.total > 0) {
                    return 'red';
                }
                else if(this.order.paidUp < this.order.total) {
                    return 'orange';
                }
                else if(this.order.paidUp >= this.order.total) {
                    return 'green';
                }
                else {
                    return 'red';
                }
            }
        }
    };
</script>
